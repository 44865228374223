import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


import Anchor from '../Icons/Anchor';
import Date from '../Format/Date';
import StaticResourceText from '../../components/Format/static-resource-text';

const BlogSection = ({ bottomspace, blogs, resources, notopspace }) => {
    return (
        <>
            <div className={(bottomspace ? 'container-mb' : '') + " bg-gray"}>
                <section className={"container"+(notopspace ? ' pt0' : "")}>
                    <div className="container__title">
                        <h2 className="align-center">
                            <StaticResourceText resources={resources} reskey={'BLOGS-TITLE'}></StaticResourceText>
                        </h2>
                    </div>
          
                    <div className="holder-blogs">
                        <Swiper
                                modules={[Pagination, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                className='single_full_width '
                                pagination={{ clickable: true }}                  
                            >
                            {blogs && blogs.map((blog, i) => {
                                    return (<SwiperSlide key={blog.title}>
                                        <div className="blog">
                                                <div className="blog__image">
                                                    <img src={blog.image} alt={blog.title} srcSet="" />
                                                </div>
                                                <div className="blog__content">
                                                    <div className='flow'>
                                                        <h3 className="blog__title--1 color-black">{blog.title}</h3>
                                                        <p className="blog__desc--1 color-black"></p>
                                                        <p className="blog__title--2 color-gray-dark"><em>{blog.authorName}</em>{" - "}{blog.date && <Date dateString={blog.date}></Date>} </p>
                                                    </div>
                                                    {blog.anchorlink && <Anchor className="read-more" anchorText="READ MORE" icon={true} link={'/blogs' + blog.anchorlink}></Anchor>}
                                                </div>
                                            </div>
                                    </SwiperSlide>)
                                })}
                        </Swiper>
                    </div>
                </section>
            </div>
        </>
    )
}
export default BlogSection