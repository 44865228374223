
import React, { useEffect,useState } from 'react';
import BlogTemplate from '../Common/blog-template';
import Contact from '../Common/Contact';
import Hero from '../Common/Hero';
import InfographicsTemplate from '../Common/InfographicsTemplate';
import PageNotFound from '../Common/PageNotFound';
import Parallax from '../Common/Parallax';
import TestimonialExcerptSection from '../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../Common/TestimonialTemplate';
import CommonObj from "../CommonObj";
import parallax ,{ scrollRefresh }from '../../assets/js/Components/parallax';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import faq  from '../../assets/js/Components/faq';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import mapInteractive from '../../assets/js/Components/map';
import StaticResourceText from '../Format/static-resource-text';
import WorldMap from '../map/WorldMap';
import BranchTemplate from '../Common/branch-template';
import FAQTemplate from '../Common/faq-template';
import Section from '../Section/Section';
import { Helmet } from 'react-helmet';
import '../../components/loader.css';
import PreLoad from '../Common/preload';
import CommonFunc from '../../assets/js/Components/CommonFunc';
import { scrollMemory } from '../../assets/js/Components/parallax';
import BreadCrumb from '../Common/breadcrumb';
function ContactUs({ resources }) 
{
    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);
    const fetchData = async () => 
    {
        let pageName = CommonObj.PageNameList.ContactUs;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify( pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
        
    }
    function PageScroller()
    {
      const timer = setTimeout(() => 
      {
        setLoading(false);
      }, 3000);
    }
    
    useEffect(() => {
        // fetch call used to be here
         fetchData();
         PageScroller();
    },[]);

    useEffect(() => {

      if (page != null) {
        heroCarousel();
        faq();
        parallax();
        scrollRefresh();
        mapInteractive();
        return () => 
        {
            scrollMemory(); 
        };
      }
      window.scrollTo(0, 0);
      hidePreLoad();
      resizeWindow();
    });



  if (page != null) {
    let branches = page.contents.filter(x => x.type == CommonObj.ContentType.Branches);
    let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
    let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
    let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
    let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
    let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
    let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
    
    return (
        <>
       {loading ?  (<PreLoad></PreLoad>) : (
  
  <Section PageName={page.name} async>
        <Helmet>
          <title>{page.title}</title>
          <meta name="description" content={page.metaDescription} />
          <meta name="keywords" content={page.metaKeywords} />
          {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
        </Helmet>
        <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
        <Hero landingpage={false} heading1={page.shortDesc} resources={resources} carouselList={heroCarouselList} contactUs={false}></Hero>
        <BreadCrumb page={page?.name}/>
        <div className="contactus bg-gray mat-10" async>
          <section className="container">
            <div className="inner  maxwidth900">
              <Contact resources={resources}></Contact>
            </div>
          </section>
        </div>
        
        <div className="bg-gray">
          <h2 className="align-center mt0"><StaticResourceText resources={resources} reskey={'CU-LOCATIONS-TITLE'}></StaticResourceText></h2>
          <div className="worldmap__wrapper">
            <div className="worldmap__holder">
              <WorldMap></WorldMap>
              {branches.length && <BranchTemplate branches={branches}></BranchTemplate>}
            </div>
          </div>
        </div>
        {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
        {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={resources}></TestimonialExcerptSection>}
        {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} resources={resources}></TestimonialTemplate>}
        {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={false} resources={resources}></BlogTemplate>}
        {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={resources}></FAQTemplate>}
      </Section>
        )}
      </>
    )
  }
  else {
    return(
      <>
      <PreLoad></PreLoad>
      {/* {loading ?  (<PreLoad></PreLoad>) : (
          <PageNotFound></PageNotFound>
      )} */}
      </>
      )
  }

}



export default ContactUs;