import React, { useState, useEffect } from 'react';
import CommonObj from '../../../CommonObj';
import heroCarousel from '../../../../assets/js/Components/heroCarousel';
import carousel from '../../../../assets/js/Components/carousel';
import faq from '../../../../assets/js/Components/faq';
import { collaspeMenu } from '../../../../assets/js/Components/navigation';
import hidePreLoad from '../../../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../../../assets/js/Components/parallax';
import resizeWindow from '../../../../assets/js/Components/resizeWindow';
import Parallax from '../../../Common/Parallax';
import Hero from '../../../Common/Hero';
import TestimonialExcerptSection from '../../../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../../../Common/TestimonialTemplate';
import FAQTemplate from '../../../Common/faq-template';
import PageNotFound from '../../../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../../../Section/Section';
import InfographicsTemplate from '../../../Common/InfographicsTemplate';
import RBAIconSection from '../../rba-icon-section';
import RBAIconLinkSection from '../../rba-iconlink-section';
import RBAPanelSection from '../../rba-panel-section';
import BlogSection from '../../blog_section';
import YoutubePopUp from '../../../Common/youtubepopup';
import '../../../../components/loader.css'
import PreLoad from '../../../Common/preload';
import CommonFunc from '../../../../assets/js/Components/CommonFunc';
import retirementVideo from '../../../../assets/js/Components/retirement_video';
import BreadCrumb from '../../../Common/breadcrumb';

function SWS_OT_RBA({ resources }) {
    
    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);
    const [size, setSize] = useState([0, 0]);
    
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.SWSOperationTransformationRBA;
        console.log(JSON.stringify(pageName));
        console.log(CommonObj.Endpoints.GetPage);
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
    }
    
 function PageScroller()
 {
   const timer = setTimeout(() => 
   {
     setLoading(false);
   }, 3000);
 }

    useEffect(() => {
        // fetch call used to be here
        fetchData();
        collaspeMenu();
      
        PageScroller();

    }, []);

    useEffect(() => {
        if (page != null) {
            heroCarousel();
            carousel();
            faq();
            parallax();
            scrollRefresh();
            retirementVideo();
        }
        window.scrollTo(0, 0);
        hidePreLoad();
        resizeWindow();
    }, [loading])
    if (page != null) {

        let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials)
        let icons = page.contents.filter(x => x.type == CommonObj.ContentType.Icons);
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        const parse = require('html-react-parser');
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        let panels = page.contents.filter(x => x.type == CommonObj.ContentType.Panels);
        let outcomeicons = page.contents.filter(x => x.type == CommonObj.ContentType.Thumbnails);
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        return (
            <>
                {loading ? (<PreLoad></PreLoad>) : (
                    <>
                       <Section PageName={page.name} async>
                            <Helmet>
                                <title>{page.title}</title>
                                <meta name="description" content={page.metaDescription} />
                                {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
                                <link rel="stylesheet" href="/assets/css/sws_ot_RBA.css" />
                            </Helmet>
                            <Parallax pageheroimage={page.image ? page.image : ''} ></Parallax>
                            <Hero landingpage={false} category={page.heading} heading1={page.shortDesc} carouselList={heroCarouselList} resources={resources}></Hero>
                            <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ''} ></Parallax>
                                      <BreadCrumb page={page?.name}/>


                            <div className="bg-white mt-1">
                                <section className="container ">
                                    <div className="inner">
                                        <div className="center-content para90">
                                            {page.longDesc && parse(page.longDesc)}
                                            {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {!!icons.length && <RBAIconSection icons={icons} resources={resources}></RBAIconSection>}
                            {!!outcomeicons.length && <RBAIconLinkSection icons={outcomeicons} resources={resources}></RBAIconLinkSection>}
                            {!!panels.length && <RBAPanelSection panels={panels}></RBAPanelSection>}
                            {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={resources}></TestimonialExcerptSection>}
                            {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} notopspace={true} resources={resources}></TestimonialTemplate>}
                            {!!blogs.length && <BlogSection bgblue={false} alignleft={true} blogs={blogs} notopspace={true} resources={resources}></BlogSection>}
                            {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={resources}></FAQTemplate>}
                        </Section>
                       
                    </>
                )}
            </>
        )
    }
    else {
        return(
     
            <>
            <PreLoad></PreLoad>
            {/* {loading ?  (<PreLoad></PreLoad>) : (
                <PageNotFound></PageNotFound>
            )} */}
            </>
            )
    }
}


export default SWS_OT_RBA;