import { useLocation, Link } from "react-router-dom";
import { useMemo } from "react";
const BreadCrumb = (props) => {
    const location = useLocation()
    const parentPage = localStorage?.getItem("ParentPage");
    const parentPaths = parentPage ? parentPage.split('/').slice(3) : [];
    const currentPaths = location?.pathname ? location.pathname.split('/').filter(x => x) : [];
    
    const paths = [...parentPaths, ...currentPaths].slice(0, -1);
        const pathnames = useMemo(() => { return location.pathname.includes('/blogs') || location.pathname.includes('/casestudies')? paths :location.pathname.split('/').filter((x) => x) }, [location.pathname])
    {if  (location.pathname!="/Home" && !location.pathname.includes('/blogs') && !location.pathname.includes('/casestudies') )
    {
        return (
        <div className="pb15 bg-white ">
            <div className={'customBreadcrumbsBg container pt0 pb0 ' + props.page}>
                <ul className='customBreadcrumbs'>
                {  (!pathnames.includes("Home"))? <li className="breadcrumb-item"><a href='/'>Home</a></li> :null }
                    {pathnames.map((value, index) => 
                    {
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        const isLast = index === pathnames.length - 1;
                        const isCaptalize=value!="innovation-as-a-service";
                        var ele=document.querySelector('.sub_nav a[href='+'"'+to+'"'+"]");
                         var Linkvalue=(ele!=null  && ele?.innerText.trim()!="Overview")? ele?.innerText.replaceAll("– "," ") : value.replaceAll("-"," ");
                            return <li key={to} className={`breadcrumb-item ${isLast ? 'active' : ''}  ${isCaptalize ? 'CaptialWord' : ''}`}>
                                { isLast ? 
                                (<Link to={to} className="active"> {Linkvalue}</Link>) : 
                                ( <Link to={to}>{Linkvalue}</Link>)}
                            </li>
                        
                    })}
                </ul>
            </div>
        </div>
    
    )}
    else if(location.pathname.includes('/blogs') || location.pathname.includes('/casestudies'))
    {
               return (
            <div className="pb15 bg-white">
                <div className={'customBreadcrumbsBg container pt0 pb0 ' + props.page}>
                    <ul className='customBreadcrumbs'>
                        {(!pathnames.includes("Home")) ? <li><a className="breadcrumb-item" href='/'>Home</a></li> :null }
                        {pathnames.map((value, index) => 
                        {
                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                            const isLast = index === pathnames.length - 1;
                            value= value=="casestudies"?"Case Studies":value;
                            var ele=document.querySelector('.sub_nav a[href='+'"'+to+'"'+"]");
                            var Linkvalue=(ele!=null  && ele?.innerText.trim()!="Overview" )? ele?.innerText.replaceAll("– "," ") : value.replaceAll("-"," ");
                                return <li key={to} className={`breadcrumb-item ${isLast ? 'active' : ''} `}>
                                    { isLast ? 
                                    (<span className="active"> {Linkvalue}</span>) : 
                                    ( <Link to={to}>{Linkvalue}</Link>)}
                                </li>
                            
                        })}
                    </ul>
                </div>
            </div>
        
        )}
    }
   }


export default BreadCrumb;