import React, { useState, useEffect, useContext, useMemo } from 'react';
import CommonObj from '../../../CommonObj';
import heroCarousel from '../../../../assets/js/Components/heroCarousel';
import carousel from '../../../../assets/js/Components/carousel';
import faq from '../../../../assets/js/Components/faq';
import { collaspeMenu } from '../../../../assets/js/Components/navigation';
import hidePreLoad from '../../../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../../../assets/js/Components/parallax';
import resizeWindow from '../../../../assets/js/Components/resizeWindow';
import videoInit from '../../../../assets/js/Components/video';
import formInit from '../../../../assets/js/Components/formInit';
import Parallax from '../../../Common/Parallax';
import Hero from '../../../Common/Hero';
import TestimonialExcerptSection from '../../../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../../../Common/TestimonialTemplate';
import BlogTemplate from '../../../Common/blog-template';
import FAQTemplate from '../../../Common/faq-template';
import PageNotFound from '../../../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../../../Section/Section';
import PanelTemplate from '../../../Common/panel-template';
import InfographicsTemplate from '../../../Common/InfographicsTemplate';
import DesignCreativeIconSection from '../../DesignCreativeIconSection';
import PresentationServicesPanelSection from '../../PresentationServicesPanelSection';
import PSIconSection2 from '../../PSIconSection2';
import OfferSection1 from '../../offer-section-1';
import PresentationServicesIconSection from '../../PresentationServicesIconSection';
import OfferSection2 from '../../offer-section-2';
import { CircleLoader } from 'react-spinners';
import '../../../../components/loader.css'
import PreLoad from '../../../Common/preload';
import CommonFunc from '../../../../assets/js/Components/CommonFunc';
import VideoPopUp from '../../../Common/VideoPopup';
import { Context } from '../../../MainSection/MainSection';
import BreadCrumb from '../../../Common/breadcrumb';

function GBS_PS_PS({ resources }) {

  const [page, setPage] = useState();
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState([0, 0]);
  const [videos, setVideos] = useContext(Context);
  const fetchData = async () => {
    let pageName = CommonObj.PageNameList.GBSCreativeProductionPresentationServices;
    let res = await fetch(CommonObj.Endpoints.GetPage, {
      method: 'POST',
      body: JSON.stringify(pageName),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let page = await res.json()
    setPage(page);
    setLoading(false);
    CommonFunc(page);
  }

  function PageScroller() {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
  }



  useEffect(() => {
    // fetch call used to be here
    fetchData();
    collaspeMenu();
    PageScroller();
  }, []);

  useEffect(() => {
    if (page != null) {
      heroCarousel();
      carousel();
      faq();
      formInit();
      parallax();
      videoInit();
    }
    window.scrollTo(0, 0);
    hidePreLoad();
    resizeWindow();
  }, [loading])


  if (page != null) {
    let landingpage = true;
    let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
    let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials)
    let icons = page.contents.filter(x => x.type == CommonObj.ContentType.Icons);
    let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
    const parse = require('html-react-parser');
    let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
    let panels = page.contents.filter(x => x.type == CommonObj.ContentType.Panels);
    let outcomeicons = page.contents.filter(x => x.type == CommonObj.ContentType.Thumbnails);
    let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
    let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
    let section1Icons = outcomeicons.filter(icon => icon.tags && icon.tags.toString().includes("GBS-PAS-PS-ICON-1"));
    let section2Icons = outcomeicons.filter(icon => icon.tags && icon.tags.toString().includes("GBS-PAS-PS-THUMBNAILS-TITLE"));
    let videoContent = page.contents.filter(x => x.type == CommonObj.ContentType.Videos);
    const offerButtonOne = resources != null ? resources.filter((x) => x.name === "GBS-PRESENTATION-OFFER-BUTTON-1") : [];
    const offerButtonTwo = resources != null ? resources.filter((x) => x.name === "GBS-PRESENTATION-OFFER-BUTTON-2") : [];
    setVideos(videoContent);
    return (
      <>
        {loading ? (<PreLoad></PreLoad>) : (
          <>
            <Section PageName={page.name}>
              <Helmet>
                <title>{page.title}</title>
                <meta name="description" content={page.metaDescription} />
                {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
              </Helmet>
              <Parallax pageheroimage={page.image ? page.image : ''} ></Parallax>
              <Hero landingpage={landingpage} category={page.heading} heading1={page.shortDesc} carouselList={heroCarouselList} resources={resources}></Hero>
              <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ''} ></Parallax>
                        <BreadCrumb page={page?.name}/>

              <div className="bg-white mt-1">
                <section className="container">
                  <div className="inner">
                    <div className="center-content para90">
                      {page.longDesc && parse(page.longDesc)}
                      {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                    </div>
                  </div>
                </section>
              </div>
              {!!panels.length && <PresentationServicesPanelSection panels={panels} resources={resources}></PresentationServicesPanelSection>}
              {!!section1Icons.length && <PSIconSection2 icons={section1Icons} resources={resources} title={'GBS-PAS-PS-ICON-1-TITLE'}></PSIconSection2>}
              {!!offerButtonOne.length && <OfferSection1 resources={resources}></OfferSection1>}
              {!!icons.length && <PresentationServicesIconSection videos={videoContent} resources={resources}></PresentationServicesIconSection>}
              {!!section2Icons.length && <PSIconSection2 icons={section2Icons} resources={resources} title={'GBS-PAS-PS-THUMBNAILS-TITLE'} isPreMediaPress={true}></PSIconSection2>}
              {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={resources}></TestimonialExcerptSection>}
              {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} notopspace={true} resources={resources}></TestimonialTemplate>}
              {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={true} resources={resources}></BlogTemplate>}
              {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={resources}></FAQTemplate>}
              {!!offerButtonTwo.length && <OfferSection2 resources={resources}></OfferSection2>}

            </Section>

          </>
        )}
      </>
    )
  }
  else {
    return (

      <>
        <PreLoad></PreLoad>
        {/* {loading ? (<PreLoad></PreLoad>) : (
              <PageNotFound></PageNotFound>
          )} */}
      </>
    )
  }
}

export default GBS_PS_PS;