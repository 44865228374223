
import React, { useEffect,useState } from "react";
import BlogHero from "../Common/blog-hero";
import PageNotFound from "../Common/PageNotFound";
import Parallax from "../Common/Parallax";
import CommonObj from "../CommonObj";
import Arrow from "../Icons/Arrow";
import hidePreLoad from "../../assets/js/Components/hidePreLoad";
import {mobileMenu,navigationInit,} from "../../assets/js/Components/navigation";
import parallax, {scrollRefresh,} from "../../assets/js/Components/parallax";
import resizeWindow from "../../assets/js/Components/resizeWindow";
import Section from "../Section/Section";
import { Helmet } from "react-helmet";
import {useParams} from "react-router-dom";
import PreLoad from "../Common/preload";
import BreadCrumb from "../Common/breadcrumb";
import blogPgFunc from "../../assets/js/Components/blogPgFunc";
export default function Blog({ resources }) {
  const parse = require("html-react-parser");
  const {PageName} = useParams();
  // const { asPath } = useRouter();
  const [page, setPage] = useState();
    const fetchData = async () => 
    {
        let pageName = "/"+PageName;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify( pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        
    }
    
    useEffect(() => {
        // fetch call used to be here
          fetchData();
    },[]);

  useEffect(() => {
  
    if (page && page.blog != null) {
      mobileMenu();
      blogPgFunc(page);
    }
    parallax();
    scrollRefresh();

    window.scrollTo(0, 0);
    hidePreLoad();
    resizeWindow();
  });

  if (page && page.blog != null) {
    let blogtitle=page.blog && page.blog.title.replace(/(<([^>]+)>)/gi, "")
    return (
      <>
      <Section PageName={page.name} async>
        <Helmet>
          <title>{blogtitle}</title>
          <meta name="description" content={page.metaDescription} />
          <meta name="keywords" content={page.metaKeywords}/>
          {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
        </Helmet>

        <Parallax blogImage={page.image ? page.image : ""}></Parallax>

        <BlogHero title={page.blog ? page.blog.title : ""} blogImage={page.blog ? page.blog.image : ""} authorname={page.blog ? page.blog.authorName : ""}
          blogDate={page.blog ? page.blog.date : ""} authorimg={page.blog ? page.blog.authorImage : ""} resources={resources}></BlogHero>
        <BreadCrumb page={page?.name}/>
        <div className="blogs-page mt-1">
          <section className="container">
            <div className="inner maxwidth900">
              {page.blog && parse(page.blog.longDesc)}

              <div className="link-back">
                <a
                  rel="noopener noreferrer"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    window.history.back();
                    //window.location.href =history.back();
                    return false;
                  }}
                  className="btn {{class}}"
                >
                  <div className="btn__icon">
                    <Arrow></Arrow>
                  </div>
                  <div className="btn__text">back</div>
                </a>
              </div>
            </div>
          </section>
        </div>
      </Section>
      </>
    );
  } 
   else {
    return <PreLoad></PreLoad>;
   }
}


