import {scrollRefresh} from './parallax'
export default function faq(){
     
     let faqs = Array.from(document.querySelectorAll('.faq'));

     function removeActive(ele){
          for(let faqContent of faqs){
               if(ele !== faqContent && faqContent.classList.contains('active')){
                    faqContent.classList.remove('active');
               }
          }
     }
     
     for(let faqContent of faqs){
          faqContent.addEventListener('click', function(e) {
               e.preventDefault();
               removeActive(faqContent);
               this.classList.toggle('active');
               scrollRefresh();
          });
     }  
     let blogBtn = Array.from(document.querySelectorAll('.read-more'));
    for(let blog of blogBtn)
    {
      blog.addEventListener('click', function(e) { 
            localStorage.setItem("ParentPage", window.location.href);
           });
     }
     let caseStudyBtn = Array.from(document.querySelectorAll('.success-story'));
    for(let caseStudy of caseStudyBtn)
    {
      caseStudy.addEventListener('click', function(e) { 
            localStorage.setItem("ParentPage", window.location.href);
           });
     }

}