export default function blogPgFunc(page){
     
     if(page!=null)
          { 
            
            if (window.location.href.includes("/blogs/") || window.location.href.includes("/casestudies/")) 
            {
               const myDiv = document.querySelector(".blogs-page");
               if(myDiv)
               {
                  const computedStyle = window?.getComputedStyle(myDiv);
                  if (computedStyle?.backgroundColor == 'rgba(0, 0, 0, 0)')
                  {
                    var element=document.querySelector(".pb15");
                    element?.classList.remove("bg-white");
                  }
                }
            }
}
}



