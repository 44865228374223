import React, { useState, useEffect } from 'react';
import StaticResourceText from '../Format/static-resource-text';
import MenuArrow from '../Icons/MenuArrow';
import { NavLink , useLocation} from "react-router-dom";


const Nav = (props) => {
    const [openMenu, setOpenMenu] = useState(null);
    const location = useLocation();

    const handleToggle = (menu) => {
        setOpenMenu(openMenu === menu ? null : menu);
    };

    useEffect(() => {
        setOpenMenu(null);
    }, [location]);

    return (
        <div async>
            <div className="nav__wrapper">
                <div className="container menu__container">
                    <div className="header_container">
                        <header id="header">
                            <h1 className="visuallyhidden">{props.h1Tag}</h1>
                            <a rel="noopener noreferrer" id="skiptocontent" href="#header">Skip to Content</a>

                            <div className="section1">
                                <div id="logo_container">
                                    <div id="logo">
                                        <a rel="noopener noreferrer" href={"/"}><img src="/assets/img/rrd_logo.svg" alt="Go Creative Home Page" /></a>
                                    </div>
                                    <h2 className="visually-hidden">Mobile Menu</h2>
                                    <h3>Menu</h3>
                                    <div className="mobile_menu_container">
                                        <div className="mobile_menu">
                                            <a rel="noopener noreferrer" id="menu-toggle" className="menu-toggle" aria-label="Open the menu" href="#">
                                                <img src='/assets/img/menu.svg' role='button' alt='Open' />
                                            </a>
                                        </div>
                                        <div className="mobile_menu_close" >
                                            <a rel="noopener noreferrer" id="mobile_closebtn" href="#">
                                                <img src='/assets/img/close.svg' role='button' alt="mobile close menu" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="nav1" className="main_nav">
                                    <ul className="disclosure-nav">
                                        {/* <li className="{{#ifpage 'index'}}active{{/ifpage}}">
                                            <div className="button">
                                                <NavLink  to="/"><StaticResourceText resources={props.resources} reskey={'HOME-LINK'}></StaticResourceText></NavLink>
                                            </div>
                                        </li> */}
                                        <li>
                                            <div className="button">
                                                <NavLink to="/about-us"><StaticResourceText resources={props.resources} reskey={'ABOUTUS-LINK'}></StaticResourceText></NavLink>
                                            </div>

                                        </li>
                                        <li>
                                            <div className="button">
                                                <NavLink to="/Resources"><StaticResourceText resources={props.resources} reskey={'RESOURCES-LINK'}></StaticResourceText></NavLink>
                                            </div>

                                        </li>
                                        <li>
                                            <div className="button" >
                                                <NavLink to="/People"><StaticResourceText resources={props.resources} reskey={'PPL-LINK'}></StaticResourceText></NavLink>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="button">
                                                <NavLink to="/contact-us"><StaticResourceText resources={props.resources} reskey={'CU-LINK'}></StaticResourceText></NavLink>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="button">
                                                <NavLink className='brd-btn' to={"/global-brand-solutions/creative-production/presentation-services"}><StaticResourceText resources={props.resources} reskey={'EXP_CTR-LINK'}></StaticResourceText></NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                                <nav aria-label="nav2" className="sub_nav">
                                    <ul className="disclosure-nav">
                                        <li>
                                            <button className={`button ${openMenu === 'globalBrandSolutions' ? 'button-active' : 'button-inactive'}`}
                                                onClick={() => handleToggle('globalBrandSolutions')}
                                                aria-expanded={openMenu === 'globalBrandSolutions'}
                                                aria-controls="id_globalbrandsolution_menu">
                                                <StaticResourceText resources={props.resources} reskey={'GBS-DROPDOWN-TITLE'}></StaticResourceText>&nbsp;
                                                <MenuArrow></MenuArrow>
                                            </button>
                                            <ul id="id_globalbrandsolution_menu" className="menu_pad20">
                                                <li> <NavLink to={"/global-brand-solutions"}><StaticResourceText resources={props.resources} reskey={'GBS-OV-LINK'}></StaticResourceText> </NavLink></li>
                                                <li> <NavLink to={"/global-brand-solutions/content-editorial"}><StaticResourceText resources={props.resources} reskey={'GBS-CONT_EDIT-LINK'}></StaticResourceText></NavLink> </li>
                                                <li> <NavLink to={"/global-brand-solutions/branding-creative"}><StaticResourceText resources={props.resources} reskey={'GBS-DSG_CREAT-LINK'}></StaticResourceText></NavLink></li>
                                                <li className="has-submenu">
                                                    <NavLink to={"/global-brand-solutions/creative-production"}><StaticResourceText resources={props.resources} reskey={'GBS-PAS-LINK'}></StaticResourceText></NavLink>
                                                    <NavLink className="submenu" to={"/global-brand-solutions/creative-production/presentation-services"}>&ndash; <StaticResourceText resources={props.resources} reskey={'GBS-PAS-PS-LINK'}></StaticResourceText></NavLink>
                                                    <NavLink className="submenu" to={"/global-brand-solutions/creative-production/accessibility-remediation"}>&ndash; <StaticResourceText resources={props.resources} reskey={'GBS-PAS-AR-LINK'}></StaticResourceText></NavLink>
                                                    {/* <NavLink className="submenu" to={"/global-brand-solutions/creative-production/3d-solutions"}>&ndash; <StaticResourceText resources={props.resources} reskey={'GBS-PAS-3DSOL-LINK'}></StaticResourceText></NavLink> */}
                                                    <StaticResourceText resources={props.resources} reskey={'IMMERSIVE-AND-INTERACTIVE'}></StaticResourceText>
                                                    <NavLink className="submenu" to={"/global-brand-solutions/creative-production/premedia-prepress"}>&ndash; <StaticResourceText resources={props.resources} reskey={'GBS-PAS-PREMEDIA-PRESS-LINK'}></StaticResourceText></NavLink>                                             </li>
                                                <li> <NavLink to={"/global-brand-solutions/marketing-automation-insights"}><StaticResourceText resources={props.resources} reskey={'GBS-MI-LINK'}></StaticResourceText></NavLink></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <button className={`button ${openMenu === 'smartWorkSolutions' ? 'button-active' : 'button-inactive'}`}
                                                onClick={() => handleToggle('smartWorkSolutions')}
                                                aria-expanded={openMenu === 'smartWorkSolutions'}
                                                aria-controls="id_smartresources_menu">
                                                <StaticResourceText resources={props.resources} reskey={'SWS-DROPDOWN-TITLE'}></StaticResourceText>&nbsp;
                                                <MenuArrow></MenuArrow>
                                            </button>
                                            <ul id="id_smartresources_menu" className="menu_pad20">
                                                <li>
                                                    <NavLink to={"/smart-work-solutions"}> <StaticResourceText resources={props.resources} reskey={'SWS-OV-LINK'}></StaticResourceText></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={"/smart-work-solutions/productivity-solutions"}><StaticResourceText resources={props.resources} reskey={'SWS-PAS-LINK'}></StaticResourceText>
                                                    </NavLink>
                                                </li>
                                                <li className="has-submenu">
                                                    <NavLink to={"/smart-work-solutions/operations-transformation"}><StaticResourceText resources={props.resources} reskey={'SWS-OT-LINK'}></StaticResourceText></NavLink>
                                                    <NavLink className="submenu" to={"/smart-work-solutions/operations-transformation/retirement-benefits-administration"}>&ndash; <StaticResourceText resources={props.resources} reskey={'SWS-RBA-LINK'}></StaticResourceText></NavLink>
                                                    <NavLink className="submenu" to={"/smart-work-solutions/operations-transformation/insurance-services"}>&ndash; <StaticResourceText resources={props.resources} reskey={'SWS-OT-IS-LINK'}></StaticResourceText></NavLink>
                                                    <NavLink className="submenu" to={"/smart-work-solutions/operations-transformation/reit-accounting-services"}>&ndash; <StaticResourceText resources={props.resources} reskey={'SWS-OT-REIT-LINK'}></StaticResourceText></NavLink>
                                                    <NavLink className="submenu" to={"/smart-work-solutions/operations-transformation/utilities"}>&ndash; <StaticResourceText resources={props.resources} reskey={'SWS-OT-UTIL-LINK'}></StaticResourceText></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={"/smart-work-solutions/shared-services-transformation"}><StaticResourceText resources={props.resources} reskey={'SWS-SST-LINK'}></StaticResourceText></NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <button className={`button ${openMenu === 'technologyInnovationServices' ? 'button-active' : 'button-inactive'}`}
                                                onClick={() => handleToggle('technologyInnovationServices')}
                                                aria-expanded={openMenu === 'technologyInnovationServices'}
                                                aria-controls="id_technologyinnovationservices_menu">
                                                <StaticResourceText resources={props.resources} reskey={'TIS-DROPDOWN-TITLE'}></StaticResourceText>&nbsp;
                                                <MenuArrow></MenuArrow>
                                            </button>
                                            <ul id="id_technologyinnovationservices_menu" className="menu_pad20">
                                                <li><NavLink to={"/technology-innovation-solutions"}><StaticResourceText resources={props.resources} reskey={'TECH_INNOV-OV-LINK'}></StaticResourceText></NavLink></li>
                                                <li><NavLink to={"/technology-innovation-solutions/innovation-as-a-service"}><StaticResourceText resources={props.resources} reskey={'TECH_INNOV-INNOV_SERV-LINK'}></StaticResourceText></NavLink></li>
                                                <li><NavLink to={"/technology-innovation-solutions/intelligent-automation-solutions"}><StaticResourceText resources={props.resources} reskey={'TECH_INNOV-IAS-LINK'}></StaticResourceText></NavLink></li>
                                                <li><NavLink to={"/technology-innovation-solutions/data-services"}><StaticResourceText resources={props.resources} reskey={'TECH_INNOV-DS-LINK'}></StaticResourceText></NavLink></li>
                                                <li><NavLink to={"/technology-innovation-solutions/it-services"}><StaticResourceText resources={props.resources} reskey={'TECH_INNOV-IT_SERVICES-LINK'}></StaticResourceText></NavLink></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </header>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Nav