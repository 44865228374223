import React from "react";
import Arrow from "./Arrow";

const Anchor = ({ className, anchorText, link, icon}) => {
  return (
    <>
      <a rel="noopener noreferrer" href={link} className={"btn " + className }>
        <div className="btn__text ">{anchorText}</div>
        {icon && (
          <div className="btn__icon ">
            <Arrow></Arrow>
          </div>
        )}
      </a>
    </>
  );
};
export default Anchor;
